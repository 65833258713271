import { HttpError } from './HttpError';

export interface HttpRequest {
	method: string;
	url: string;
	body?: object;
	authToken?: string;
}

export class HttpClient {
	public onUnauthorizedError?: () => void;

	public async request<T>(request: HttpRequest): Promise<T> {
		const headers: Record<string, string> = {};
		if (request.body) {
			headers['Content-Type'] = 'application/json';
		}
		if (request.authToken) {
			headers['Authorization'] = `Bearer ${request.authToken}`;
		}

		const response = await fetch(request.url, {
			headers,
			method: request.method,
			body: request.body ? JSON.stringify(request.body) : undefined
		});

		const status = response.status;
		if (status < 200 || status >= 300) {
			let data: object | null = null;
			let message: string | null = null;
			try {
				const json = await response.json();
				if (typeof json === 'object') {
					data = json;
					if (json['error']) {
						message = json['error'];
					}
				}
			} catch (e) {}

			if (status === 401 && this.onUnauthorizedError) {
				this.onUnauthorizedError();
			}
			if (message) {
				throw new HttpError(message, status, data);
			}
			if (status === 404) {
				throw new HttpError('Resource not found', status, data);
			}
			if (status === 500) {
				throw new HttpError('Internal server error', status, data);
			}
			throw new HttpError('Unknown error', status, data);
		}

		return await response.json();
	}

	public static readError(error: any): string {
		if (error instanceof Error) {
			return error.message;
		}
		return String(error);
	}
}
