import { useRef, useState } from 'react';
import { FullscreenLayout } from '../../../components/layout/FullscreenLayout';
import { SignIn } from '../../../components/theme/signIn/SignIn';
import { usePublicAuthApiClient } from '../../../api/PublicAuthApiClient';
import { useAuthContextState } from '../../../auth/AuthContext';
import { useNavigate } from 'react-router';

export function SignInRoute() {
	const apiClient = usePublicAuthApiClient();
	const navigate = useNavigate();
	const { setSession } = useAuthContextState();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState<string | null>(null);
	const isProcessing = useRef(false);

	async function onSubmit() {
		if (isProcessing.current) {
			return;
		}
		isProcessing.current = true;
		if (error) {
			setError(null);
		}

		try {
			const response = await apiClient.signIn({
				email,
				password
			});
			setSession({
				userId: response.userId,
				email: response.email,
				token: response.token
			});
			navigate('/dashboard');
		} catch (e) {
			const error = e instanceof Error ? e.message : String(e);
			setError(error);
		} finally {
			isProcessing.current = false;
		}
	}

	return (
		<FullscreenLayout>
			<SignIn
				username={email}
				password={password}
				error={error}
				onUsernameChanged={setEmail}
				onPasswordChanged={setPassword}
				onSubmit={onSubmit}
			/>
		</FullscreenLayout>
	);
}
