import { GetCreditUsageResponse, GetPurchaseLinkResponse } from '@visiopilot/admin-model';
import { useMemo } from 'react';
import { HttpClient } from './HttpClient';
import { AuthSession, useSession } from '../auth/AuthContext';
import { useHttpClient } from './HttpClientContext';

export function useCreditsApiClient() {
	const httpClient = useHttpClient();
	const session = useSession();
	return useMemo(() => new CreditsApiClient(httpClient, session), [httpClient, session]);
}

export class CreditsApiClient {
	public constructor(
		private readonly httpClient: HttpClient,
		private readonly session: AuthSession
	) {}

	public async getUsage(): Promise<GetCreditUsageResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/credits/usage`,
			authToken: this.session.token
		});
	}

	public async getPurchaseLink(): Promise<GetPurchaseLinkResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/credits/purchase-link`,
			authToken: this.session.token
		});
	}
}
