import { CreditUsageDto } from '@visiopilot/admin-model';
import { Button } from '../primitives/Button';

export interface CurrentUsageProps {
	usage: CreditUsageDto;
	onBuyCreditsRequested: (packName: string) => void;
}

export function CurrentUsage(props: CurrentUsageProps) {
	const hasAnyCredits = props.usage.availableCredits > 0;
	return (
		<div className="flex items-center w-full mt-3 px-4 py-1.5 bg-blue-50 rounded-md">
			<div className="flex-1 text-sm text-gray-400">
				{hasAnyCredits && <CurrentUsageDetails usage={props.usage} />}
				{!hasAnyCredits && <>You don't have any available credits. Please top up your account</>}
			</div>
			<div>
				<Button onClicked={() => props.onBuyCreditsRequested('start')} size="md">
					Top up credits
				</Button>
			</div>
		</div>
	);
}

function CurrentUsageDetails(props: { usage: CreditUsageDto }) {
	return (
		<div>
			Available credits: <span className="text-black">{props.usage.availableCredits}</span>
		</div>
	);
}
