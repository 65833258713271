import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { DashboardRoute } from './routes/dashboard/DashboardRoute';
import { SignInRoute } from './routes/auth/signIn/SignInRoute';
import { AuthContext } from './auth/AuthContext';
import { AuthGate } from './routes/AuthGate';
import { HomeRoute } from './routes/home/HomeRoute';
import { SignUpRoute } from './routes/auth/signUp/SignUpRoute';
import { HttpClientContext } from './api/HttpClientContext';
import { ChangePasswordRoute } from './routes/auth/changePassword/ChangePasswordRoute';
import { RechargeRoute } from './routes/dashboard/RechargeRoute';

const router = createBrowserRouter([
	{
		path: '/',
		element: <HomeRoute />
	},
	{
		path: '/signin',
		element: <SignInRoute />
	},
	{
		path: '/signup',
		element: <SignUpRoute />
	},
	{
		path: '/change-password',
		element: <AuthGate route={<ChangePasswordRoute />} />
	},
	{
		path: '/dashboard',
		element: <AuthGate route={<DashboardRoute />} />
	},
	{
		path: '/recharge',
		element: <AuthGate route={<RechargeRoute />} />
	}
]);

export function App() {
	return (
		<HttpClientContext>
			<AuthContext>
				<RouterProvider router={router} />
			</AuthContext>
		</HttpClientContext>
	);
}
