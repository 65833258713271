import { useNavigate } from 'react-router';
import { useAuthContextState } from '../../auth/AuthContext';
import { Main } from '../theme/layout/Main';
import { Navbar } from '../theme/layout/Navbar';
import { Root } from '../theme/layout/Root';

export interface DefaultLayoutProps {
	children: React.ReactNode;
}

export function DefaultLayout(props: DefaultLayoutProps) {
	const { setSession, session } = useAuthContextState();
	const navigate = useNavigate();

	function onSignOutClicked() {
		setSession(null);
		navigate('/');
	}

	function onOptionsClicked() {
		navigate('/change-password');
	}

	return (
		<Root>
			<Navbar
				userEmail={session?.email || ''}
				logoHref="/dashboard"
				items={[
					{
						label: 'Dashboard',
						href: '/dashboard'
					}
				]}
				onSignOutClicked={onSignOutClicked}
				onChangePasswordClicked={onOptionsClicked}
			/>
			<Main>{props.children}</Main>
		</Root>
	);
}
