import { useMemo } from 'react';
import { GetTraceStatsRequest, GetTraceStatsResponse } from '@visiopilot/admin-model';
import { useHttpClient } from './HttpClientContext';
import { AuthSession, useSession } from '../auth/AuthContext';
import { HttpClient } from './HttpClient';

export function useTraceApiClient() {
	const httpClient = useHttpClient();
	const session = useSession();
	return useMemo(() => new TraceApiClient(httpClient, session), [httpClient, session]);
}

export class TraceApiClient {
	public constructor(
		private readonly httpClient: HttpClient,
		private readonly session: AuthSession
	) {}

	public getTraceStats(request: GetTraceStatsRequest): Promise<GetTraceStatsResponse> {
		return this.httpClient.request({
			method: 'GET',
			url: `/api/trace-stats?fromTime=${request.fromTime}`,
			authToken: this.session.token
		});
	}
}
