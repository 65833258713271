import { useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';

const WIDGET_SCRIPT_ID = 'visiopilot-widget';

export function WidgetScript(props: { widgetId: string }) {
	useEffect(() => {
		if (document.getElementById(WIDGET_SCRIPT_ID)) {
			return;
		}

		const isLocalhost = ['localhost', '127.0.0.1'].includes(window.location.hostname);
		const host = isLocalhost ? 'http://localhost:3000' : 'https://platform.visiopilot.com';

		const script = document.createElement('script');
		script.id = WIDGET_SCRIPT_ID;
		script.async = true;
		script.src = host + '/widget.js?w=' + props.widgetId;

		document.head.appendChild(script);
	}, [props.widgetId]);

	return <Fragment />;
}
