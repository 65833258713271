import { useNavigate } from 'react-router';
import { Fragment } from 'react';

export function RechargeRoute() {
	const navigate = useNavigate();

	setTimeout(() => {
		navigate('/dashboard');
	});

	return <Fragment />;
}
